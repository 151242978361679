@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@300;400;500;600;700&display=swap");

:root {
  font-family: "Montserrat Alternates", sans-serif;
  scroll-behavior: smooth;
}

.content {
  position: relative;
  width: 90%;
  max-width: 500px;
  margin: auto;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .slider-parent {
    width: calc(100% - 96px);
  }
}
.content .content-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
  opacity: 1;
}

.content-image {
  width: 100%;
}

.content-details {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
@media (max-width: 768px) {
  .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  .content-overlay {
    opacity: 1 !important;
  }
}

.logo-svg rect {
  fill: white;
}
.logo-svg text {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 38px;
}
.logo-svg > rect {
  -webkit-mask: url(#mask);
  mask: url(#mask);
}
.slick-dots {
  bottom: -36px !important;
}
.slick-dots li button:before {
  font-size: 20px !important;
  color: #c92a2a !important;
}

.nav-container {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(0.6) translateX(32px);
}

.stick {
  width: 80px;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 15px;
  background-color: black;
  display: inline-block;
}

.stick:last-child {
  margin-bottom: 0px;
}

.stick-1.open {
  animation: stick-1-open 0.6s ease-out forwards;
}

.stick-2.open {
  animation: stick-2-open 0.6s linear forwards;
}

.stick-3.open {
  animation: stick-3-open 0.6s linear forwards;
}

@keyframes stick-1-open {
  0% {
    width: 80px;
  }
  40% {
    background-color: #f03e3e;
    width: 8px;
    transform: translate(40px, 0px);
  }
  75%,
  80% {
    width: 8px;
    transform: translate(40px, -50px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  100% {
    background-color: #f03e3e;
    width: 8px;
    transform: translate(35px, 46px);
  }
}

@keyframes stick-2-open {
  80% {
    background-color: black;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    background-color: #f03e3e;
    transform: translate(8px, 0px) rotate(40deg);
  }
}

@keyframes stick-3-open {
  80% {
    background-color: black;
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    background-color: #f03e3e;
    transform: translate(8px, -23px) rotate(-40deg);
  }
}

.stick-1.close {
  width: 8px;
  transform: translate(27px, 26px);
  animation: stick-1-close 0.6s ease-out forwards;
}

.stick-2.close {
  transform: translate(0px, 0px) rotate(40deg);
  animation: stick-2-close 0.6s ease-out forwards;
}

.stick-3.close {
  transform: translate(0px, -23px) rotate(-40deg);
  animation: stick-3-close 0.6s ease-out forwards;
}

@keyframes stick-1-close {
  0%,
  70% {
    width: 0px;
  }
  100% {
    width: 80px;
    transform: translate(0, 0);
  }
}

@keyframes stick-2-close {
  0% {
    background-color: #f03e3e;
    width: 80px;
  }
  20% {
    background-color: #f03e3e;
    width: 8px;
    transform: translate(0, 0px) rotate(40deg);
  }
  40% {
    background-color: black;
    width: 0px;
  }
  65% {
    transform: translate(0, -70px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  80% {
    width: 0px;
  }
  100% {
    width: 80px;
    transform: translate(0, 0px);
  }
}

@keyframes stick-3-close {
  0% {
    background-color: #f03e3e;
    width: 80px;
  }
  20% {
    background-color: #f03e3e;
    width: 8px;
    transform: translate(0, -23px) rotate(-40deg);
  }
  40% {
    background-color: black;
  }
  65% {
    transform: translate(0, -93px);
    animation-timing-function: cubic-bezier(0, 1, 1, 1);
  }
  90% {
    width: 8px;
  }
  100% {
    width: 80px;
    transform: translate(0, 0px);
  }
}

#menu {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #f5f6fa;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu > div {
  padding: 10px 0;
  transition-delay: 2s;
}

#menu > .active {
  color: #e03131;
}
.menu > .active {
  color: #e03131;
}

.slider-parent:before {
  position: absolute;
  display: block;
  width: 50px;
  content: '';
  top: 0;
  z-index: 40;
  height: 100%;
  pointer-events: none;
  background: linear-gradient( to right, #F1F3F5 20%, rgba(255, 255, 255, 0) 80% );
}

.slider-parent:after {
  position: absolute;
  display: block;
  width: 50px;
  z-index: 40;
  right: 0px;
  content: "";
  top: 0;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to left, #f1f3f5 20%, rgba(255, 255, 255, 0) 80%);
}
